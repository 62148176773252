//
// Toolbar for handheld devices
// --------------------------------------------------

.handheld-filter-icon {
  display: none;
}
@include media-breakpoint-down(lg) {
  .handheld-filter-icon-show {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 1024;
  }
}
