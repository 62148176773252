@use '@angular/material' as mat;

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

//html {
//  color-scheme: light;
//  @include mat.theme((
//    color: mat.$azure-palette,
//    typography: Roboto,
//    density: 0
//  ));
//}

html {
  color-scheme: light;
  @include mat.theme((
    color: (
      primary: mat.$azure-palette,
      //tertiary: mat.$violet-palette,
      theme-type: light,
    ),
    typography: Roboto,
    density: 0
  ));
}

//.dark-theme {
//  color-scheme: dark;
//  @include mat.theme {
//    color: mat.$azure-palette,
//  }
//}

.material-symbols-inlined {
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}

:root {
  @include mat.badge-overrides((
    background-color: #3f51b5,
    text-color: white,
  ));
}

// Customize the entire app. Change :root to your selector if you want to scope the styles.
:root {
  @include mat.icon-overrides((
    color: #8599d2,
  ));
}

.mat-icon.mat-icon-yellow {
  @include mat.icon-overrides((
    color: #eed00c,
  ));
}

.mat-button-warn {
  @include mat.button-overrides((
    //filled-container-color: orange,
    outlined-label-text-color: #F15D5D,
  ));
}

.icon-button-warn {
  @include mat.icon-button-overrides((
    icon-color: #F15D5D,
  ));
}

.mat-icon-warn {
  @include mat.icon-overrides((
    color: #F15D5D,
  ));
}

//--mat-icon-color


//body {
//  background: var(--mat-sys-surface);
//  color: var(--mat-sys-on-surface);
//}

//@include mat.elevation-classes();
//@include mat.app-background();
//
//.dense-1 {
//  @include mat.form-field-density(-1);
//}
//
//.dense-2 {
//  @include mat.form-field-density(-2);
//}


//.material-symbols-outlined {
//  font-variation-settings:
//    'FILL' 0,
//    'wght' 400,
//    'GRAD' 0,
//    'opsz' 24
//}



.success.mat-mdc-snack-bar-container {
  background-color: #20c997;
  /*background-color: #20c997;*/
  color: white;
}
.danger.mat-mdc-snack-bar-container {
  background-color: #ff5658;
  color: white;
}
.info.mat-mdc-snack-bar-container {
  background-color: #8d97d4;
  color: white;
}
.warn.mat-mdc-snack-bar-container {
  background-color: #ff5658;
  color: white;
}
.primary.mat-mdc-snack-bar-container {
  background-color: #3f51b5;
  color: white;
}
.secondary.mat-mdc-snack-bar-container {
  background-color: lightgrey;
  color: white;
}
.light.mat-mdc-snack-bar-container {
  background-color: lightblue;
  color: white;
}
.dark.mat-mdc-snack-bar-container {
  background-color: darkcyan;
  /*background-color: indigo;*/
  color: white;
}

.mat-mdc-snack-bar-container {
  &.white-snackbar {
    --mdc-snackbar-container-color: #fff;
    --mat-mdc-snack-bar-button-color: #000;
    --mdc-snackbar-supporting-text-color: #000;
  }
}
.mat-mdc-snack-bar-container {
  &.success-snackbar {
    --mdc-snackbar-container-color: #20c997;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}
.mat-mdc-snack-bar-container {
  &.info-snackbar {
    --mdc-snackbar-container-color: #4e63ec;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}
.mat-mdc-snack-bar-container {
  &.warn-snackbar {
    --mdc-snackbar-container-color: #ff5658;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}
