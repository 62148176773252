
//$navbar-stuck-box-shadow:             $box-shadow !default;
//
////
////// Breadcrumb
////
////$breadcrumb-font-size:                $font-size-base * .8125 !default;
////$breadcrumb-item-padding:             .425rem !default;
////$breadcrumb-margin-bottom:            0 !default;
////$breadcrumb-color:                    $gray-700 !default;
////$breadcrumb-hover-color:              $primary !default;
////$breadcrumb-divider-color:            darken($gray-500, 3%) !default;
////$breadcrumb-active-color:             $gray-600 !default;
////$breadcrumb-divider:                  quote("\e90a") !default;
////$breadcrumb-light-color:              $white !default;
////$breadcrumb-light-hover-color:        $white !default;
////$breadcrumb-light-active-color:       rgba($white, .6) !default;
////$breadcrumb-light-divider-color:      rgba($white, .5) !default;
//



////
////// Product card
//$product-card-hover-box-shadow:       $box-shadow-lg !default;
$product-card-transition:             all .15s ease-in-out !default;
////
////// Product list
//$product-list-thumb-width:            15rem !default;
////
////// Alternative product card
//$product-card-thumb-overlay-bg:       rgba($gray-900, .5) !default;
////
////// Product title
//$product-title-link-color:            $headings-color !default;
//$product-title-link-hover-color:      $primary !default;
$product-title-link-transition:       color .25s ease-in-out !default;
////
////// Product meta
$product-meta-color:                  $gray-600 !default;
$product-meta-hover-color:            $gray-700 !default;
$product-meta-transition:             color .25s ease-in-out !default;


//// Product gallery
$product-gallery-image-size:                5rem !default;
$product-gallery-image-spacing:             .625rem !default;
//$product-gallery-thumb-border-width:        $border-width !default;
//$product-gallery-thumb-border-color:        $border-color !default;
//$product-gallery-thumb-color:               $gray-700 !default;
//$product-gallery-thumb-font-size:           $font-size-sm !default;
//$product-gallery-thumb-border-radius:       $border-radius !default;
$product-gallery-image-transition:          border-color .2s ease-in-out !default;
//$product-gallery-thumb-hover-border-color:  darken($border-color, 8%) !default;
//$product-gallery-thumb-active-border-color: $primary !default;
//
//$product-gallery-preview-transition:        opacity .3s ease-in-out !default;

